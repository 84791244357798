void 0;
var cemf = cemf || {
    js: {}
};

cemf.js.Utility = function () {
}

cemf.js.Utility.prototype.ajaxResponseBaseTime = 3;
cemf.js.Utility.prototype.ajaxResponseRequestError = "<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição...</div>";

cemf.js.Utility.prototype.animateMenuBar = false;

cemf.js.Utility.prototype.sizeTablet = 1024;

cemf.js.Utility.prototype.url_current = function () {
    return window.location.href;
}

cemf.js.Utility.prototype.date_fmt_br = function (_date) {
    var text = "" + _date;
    var $new_date = text.replace(" ", "T");
    var $date = new Date($new_date);
    var result = $date.getDay() + "/" + $date.getMonth() + "/" + $date.getFullYear();
    return (result);
}

cemf.js.Utility.prototype.dateToPT = function (date) {
    return date.split('-').reverse().join('/');
}

cemf.js.Utility.prototype.formatNumberPT = function (number) {
    return number.toLocaleString('pt-br', {minimumFractionDigits: 2});
}

cemf.js.Utility.prototype.formatCurrency = function (number) {
    return number.toLocaleString('pt-br',{style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
}

/* Verifica se a TELA é de TABLET/CELULAR ou não, */
cemf.js.Utility.prototype.isCelular = function () {
    return (this.screenSize() < this.sizeTablet) ? true : false;
}

/* Verifica se existe um seletor*/
cemf.js.Utility.prototype.exists = function (selector) {
    return $(selector).length > 0 ? true : false;
}

/* Captura o Tamanho da tela, retornando a largura*/
cemf.js.Utility.prototype.screenSize = function (selector) {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;
    return x;
}

cemf.js.Utility.prototype.isEmail = function(email){
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

cemf.js.Utility.prototype.isName = function(name){
    var re = /[0-9]/;
    return re.test(name);
}

cemf.js.Utility.prototype.only_numbers = function(numbers){
    return numbers.replace(/\D/gim, '');
}

cemf.js.Utility.prototype.generateHash = function(numbers){
    return Math.floor(Date.now() * Math.random()).toString(23);
}


/*****************************
 *  Função de SCROOL CONFORME DATA-ROLE
 *********************/
cemf.js.Utility.prototype.scrollPage = function (selector) {
    void 0;
    self = this;
    var role = $(selector).data('role');
    if (self.exists($(role))) {
        pos = $(role).offset().top - 100;
    } else {
        pos = 0;
    }
    $('html, body').animate({
        scrollTop: pos
    }, 400, 'linear');

    $(".j_menu_mobile_close").trigger("click");

}




/*****************************
 *  DISPARO DE MENSAGENS
 *********************/
cemf.js.Utility.prototype.ajaxMessage = function (message, time) {
    var ajaxMessage = $(message);

    ajaxMessage.append("<div class='message_time'></div>");
    ajaxMessage.find(".message_time").animate({"width": "100%"}, time * 1000, function () {
        //console.log($(this).parents(".message"));
        $(this).parents(".message").addClass('animate__animated animate__slideOutRight').fadeOut(400);
    });

    $(".ajax_response").append(ajaxMessage);
    ajaxMessage.effect("bounce");
}

cemf.js.Utility.prototype.messageStart = function () {
    var self = this;
    $(".ajax_response .message").each(function (e, m) {
        self.ajaxMessage(m, self.ajaxResponseBaseTime + 1);
    });

    $(".ajax_response").on("click", ".message", function (e) {
        $(this).effect("bounce").fadeOut(1);
    });
}

/***************************************
 *  EVENTO POST PADRÃO DOS FORMULÁRIOS
 ***************************************/
cemf.js.Utility.prototype.startPostForm = function ($time) {
    var self = this;
    self.messageStart();
    $("form:not('.ajax_off')").submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var load = $("[data-modal-loading]");
        var flashClass = "ajax_response";
        var flash = $("." + flashClass);

        form.ajaxSubmit({
            url: form.attr("action"),
            type: "POST",
            dataType: "json",
            beforeSend: function () {
                void 0;
                load.fadeIn('fast');
            },
            uploadProgress: function (event, position, total, completed) {
                var loaded = completed;
                var load_title = $("[data-info]");
                load_title.text("Enviando (" + loaded + "%)");

                form.find("input[type='file']").val(null);
                if (completed >= 100) {
                    load_title.text("Aguarde...");
                }
            },
            success: function (response) {
                void 0;
                void 0;

                //redirect
                if (response.redirect) {
                    window.location.href = response.redirect;
                }

                //reload
                if (response.reload) {
                    location.reload();
                }

                //message
                if (response.message) {
                    self.ajaxMessage(response.message, ($time != null) ? $time : self.ajaxResponseBaseTime);
                }
            },
            complete: function (response) {
                void 0;

                if (response.reload == null) {
                    load.fadeOut('fast');
                }

                if (form.data("reset") === true) {
                    form.trigger("reset");
                }

                $('html, body').animate({scrollTop: 0}, 50);
            },
            error: function (event) {
                load.fadeOut('fast', function () {
                    self.ajaxMessage(self.ajaxResponseRequestError, 5);
                });
            }
        });
    })
}

cemf.js.Utility.prototype.post = function ($url, $data) {
    
    var load = $("[data-modal-loading]");
    var flashClass = "ajax_response";
    var flash = $("." + flashClass);

    void 0;

    $.ajax({
        method: "POST",
        url: $url,
        dataType: "json",
        data: $data,
        beforeSend: function() {
            void 0;
            load.find("[data-info]").html("Processando...");
            load.fadeIn('fast');
        },
        success: function(response) {
            void 0;
            void 0;

            //redirect
            if (response.redirect) {
                window.location.href = response.redirect;
            }

            //reload
            if (response.reload) {
                location.reload();
            }

            //message
            if (response.message) {
                $utility.ajaxMessage(response.message, 3);
            }

        },
        complete: function(response) {
            void 0;

            if (response.reload == null) {
                load.fadeOut('fast');
            }

        },
        error: function(event) {
            load.fadeOut('fast', function() {
                $utility.ajaxMessage("<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição...</div>", 5);
            });
        }

    });
}

cemf.js.Utility.prototype.start = function () {
    var self = this;

    /* ABERTURA DAS REPOSTAS */
    $("[data-question]").on("click", function (e) {
        e.preventDefault();
        var id = $(this).data('question');
        if ($("[data-answer =" + id + "]").is(":visible")) {
            $(this).find('a').html("+");
        } else {
            $(this).find('a').html("-");
        }
        $("[data-answer =" + id + "]").animate({'height': 'toggle'}, 300, function () {
        });
        //self.openQuestions($(this));
    });

    $("[data-question-open]").on('click', function (e) {
        e.preventDefault();
        void 0;
        self.openQuestions($(this));
    });

    /***************
     * OPEN MODAL
     ***************/
    $("[data-close]").on('click', function () {
        var close = $(this).data("close");
        $("[data-" + close + "]").fadeOut('fast');
    })

    $("[data-modal-open]").on('click', function (e) {
        e.preventDefault();
        var open = $(this).data("modal-open");
        var close = $(this).data("modal-close");
        var info = $(this).data("modal-info");
        var href = $(this).attr("href");
        void 0;
        if (info != null)
            $("[data-info]").html(info);
        if (close != null)
            $("[data-" + close + "]").fadeOut(30);

        if (href != null && href != "#") {
            window.location.href = href;
        }

        $("[data-" + open + "]").fadeIn("fast");
    })


    /* LIMITE DE CARACTERES */
    //console.log("capturando");
    $("[data-limit]").keyup(function () {
        void 0;
        var limit = $(this).data('limit');
        var id = $(this).attr('id');
        var val = $(this).val();
        if (val.length >= limit) {
            $(this).val(val.substr(0, limit));
            val = $(this).val();
        }

        $("[data-limit-" + id + "]").text(val.length + "/" + limit);
    });


    /*************************
     *  INPUT MAGIC
     *************************/

    $(".input_magic:not('.ajax_off') input").on('keyup', function () {
        var name = $(this).attr('name');
        var label = $("label[for='" + name + "']");
        var input = $(this);
        void 0;

        if ($(input).val().length >= 1) {
            $(label).addClass("inputMagicUp");
            // $(this).parent().css('margin-top','10px');
        } else {
            $(label).removeClass("inputMagicUp");
            // $(this).parent().css('margin-top','3px');
        }
    })

    /*************************
     *  INPUT TEXTAREA
     *************************/

    $(".input_magic:not('.ajax_off') textarea").on('keyup', function () {
        var name = $(this).attr('name');
        var label = $("label[for='" + name + "']");
        var input = $(this);
        void 0;

        if ($(input).val().length >= 1) {
            $(label).addClass("inputMagicUp");
            // $(this).parent().css('margin-top','10px');
        } else {
            $(label).removeClass("inputMagicUp");
            // $(this).parent().css('margin-top','3px');
        }
    })

}


